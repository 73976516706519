import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { I18nUtils } from '@medable/i18n-core';
import { LocaleContext, useLocalizeMessage } from 'libs.nucleus.i18n';
import { Spinner } from 'libs.nucleus.spinner';
import { ListInformation } from 'libs.react.components';
import { useDateTime } from 'libs.react.hooks';
import { getParticipantNextVisitWindow } from '../../helpers/participants.helpers';
export const ParticipantDetails = ({ participant, participantDetailsLoading }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { parseDateTimeShort } = useDateTime();
    if (!participant || participantDetailsLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const detailItems = [
        {
            title: translate('Language'),
            description: I18nUtils.getLanguageLabel(participant.c_locale, currentLocale, false),
        },
        {
            title: translate('Enrolled'),
            description: participant.c_enrollment_date && parseDateTimeShort(participant.c_enrollment_date),
        },
        { title: translate('Email'), description: participant.c_email },
        { title: translate('Invite Code'), description: participant.c_access_code },
        { title: translate('Cohort'), description: participant.c_visit_schedule?.c_name },
        {
            title: translate('Consented'),
            description: participant.c_status.toLowerCase() === 'consented' ? 'Consented' : 'Not Consented',
        },
        { title: translate('Next Visit'), description: getParticipantNextVisitWindow(participant) },
    ];
    return (_jsx("div", { className: 'flex flex-col gap-8', children: _jsx(ListInformation, { dataTestId: 'participant-details-list-information', items: detailItems, useDefaultEmptyDescription: true }) }));
};
