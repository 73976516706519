import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { EM_DASH } from 'libs.constants';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { InlineNotification } from 'libs.nucleus.notification';
import { useBooleanState, useToastNotification } from 'libs.react.hooks';
import { sendParticipantIniteEmail } from './helpers/participant_form.helpers';
import { OrgContext } from '../../contexts/org';
import { SiteContext } from '../../contexts/site';
import { getApiErrorMessage } from '../../helpers/api.helpers';
import { getStudyShortCode } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const ParticipantInviteModal = ({ isModalOpen, participant, onClose, onDone }) => {
    const translate = useLocalizeMessage();
    const { org } = useContext(OrgContext);
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const [studyCode, setStudyCode] = useState(undefined);
    const [isSendingEmail, setIsSendingEmail, unsetIsSendingEmail] = useBooleanState(false);
    const { addNotification } = useToastNotification();
    const getStudyInviteCode = async () => {
        try {
            const studyShortcode = await getStudyShortCode(environment?.cortexURL ?? '', environment?.code ?? '');
            setStudyCode(studyShortcode);
        }
        catch (e) {
            console.error('Error getting study short code', e);
        }
    };
    const emailCodesToParticipant = async () => {
        setIsSendingEmail();
        if (org && environment && site && participant && studyCode) {
            try {
                await sendParticipantIniteEmail({
                    environment,
                    org,
                    participant,
                    site,
                    studyInviteCode: studyCode,
                });
                addNotification({
                    title: '',
                    subtitle: translate('Invite has been sent'),
                    type: 'success',
                });
            }
            catch (e) {
                console.error('Error sending participant invite email', e);
                const message = getApiErrorMessage(e);
                addNotification({
                    title: '',
                    subtitle: message ?? translate('We were unable to process your request. Try again later.'),
                    type: 'error',
                });
            }
            finally {
                unsetIsSendingEmail();
            }
        }
    };
    useEffect(() => {
        getStudyInviteCode();
    }, []);
    return (_jsx(ModalWindow, { title: translate('Participant Invite Information'), isOpen: isModalOpen, closeWindow: onClose, footerSecondaryActionButtons: [
            {
                label: translate('Email codes to participant'),
                onClick: emailCodesToParticipant,
                disabled: isSendingEmail,
                // content: isSaving ? savingButton : undefined,
                id: 'email-invite-codes',
            },
            { label: translate('Cancel'), onClick: onClose },
        ], footerPrimaryActionButton: {
            label: translate('Done'),
            onClick: onDone ?? onClose,
            id: 'invite-modal-done',
        }, width: 'full', children: _jsxs("div", { className: 'flex flex-col h-full justify-between', children: [_jsxs("div", { className: 'flex flex-col flex-grow gap-3 items-center justify-center', children: [_jsx(Icon, { color: 'primary', name: 'profile', size: 'base' }), _jsx("h4", { className: 'nucleus-text-h4 nucleus-text-black font-extrabold', children: translate('Participant Invite Code') }), _jsx("p", { className: 'text-sm nucleus-text-neutral-500', children: translate('Please share this invite code with your participant to enable them to join from the Medable app.') }), _jsxs("div", { className: 'text-center', children: [_jsx("span", { children: translate('Study code') }), _jsx("div", { className: 'nucleus-px-6 py-1 bg-neutral-100', children: _jsx("span", { className: 'nucleus-text-h1 nucleus-font-light', children: studyCode ?? EM_DASH }) })] }), _jsxs("div", { className: 'text-center', children: [_jsx("span", { children: translate('Invite code') }), _jsx("div", { className: 'nucleus-px-6 py-1 bg-neutral-100', children: _jsx("span", { className: 'nucleus-text-h1 nucleus-font-light', children: participant?.c_access_code ?? EM_DASH }) })] })] }), !org?.c_no_pii && !participant?.c_email && (_jsx("div", { className: 'flex', children: _jsx(InlineNotification, { subtitle: translate('Please enter an email for the participant before sending invite codes'), title: translate('No Email'), type: 'warning' }) }))] }) }));
};
