import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { LocalizationStatus } from 'libs.localization.common';
import { useLocalization } from 'libs.localization.react';
import { Locale, useLocalizeMessage } from 'libs.nucleus.i18n';
import { InlineNotification } from 'libs.nucleus.notification';
import { PageHeader } from 'libs.react.components';
import { SectionExport, TableImport } from '../../components';
import { ImportPdfAction } from '../../components/import_pdf_action';
import { TranslationWorkbenchContext } from '../../contexts';
import { useAssetContext, useLocaleAccess } from '../../hooks';
import { getExportTableStatus } from '../../utils';
import { getRolebyAvailableTo } from '../../utils/data_utils';
import { toSentenceCase, toSnakeCase } from '../../utils/string_utils';
export const ResourcePdfView = ({ assetId, studyId }) => {
    const t = useLocalizeMessage();
    const [queryParams] = useSearchParams();
    const assetType = queryParams.get('assetType');
    const asset = {
        type: assetType,
        id: assetId,
        version: '1',
    };
    const { study, updateStudy, studyLocalizationStatus } = useContext(TranslationWorkbenchContext);
    const isStudyinDraft = studyLocalizationStatus !== StudyLocalizationStatus.READY;
    const { studyResources } = study.getStudyMetadata();
    const resource = studyResources.find((r) => r.id === assetId);
    const currentRole = resource?.availableTo && getRolebyAvailableTo(resource.availableTo);
    const { locales } = useContext(TranslationWorkbenchContext);
    const { getReviewUrl } = useAssetContext();
    const { localizationEntry } = useLocalization(asset);
    const { checkIsValidLocale } = useLocaleAccess(currentRole);
    const breadcrumbPages = useMemo(() => [
        { name: t('Translations'), path: `/studies/${studyId}/build/translations` },
        { name: toSentenceCase(resource?.title) || '', path: `/studies/${studyId}/build/translations/${assetId}` },
    ], [assetId, assetType]);
    const handleUpdateStudyConfig = useCallback((files) => {
        const file = files?.[0];
        if (!file || !resource) {
            return;
        }
        resource?.files?.push(file);
        const resources = [...studyResources.filter((r) => r.id !== assetId), resource];
        study.updateStudyMetadata({ studyResources: resources });
        updateStudy?.(study);
    }, [study, updateStudy, resource]);
    const supportedLocales = useMemo(() => Object.values(localizationEntry?.supported_locales || {}).filter(({ locale }) => locale !== localizationEntry?.base_locale), [localizationEntry.supported_locales, localizationEntry.base_locale]);
    const getLocale = (locale) => supportedLocales.find((l) => l.locale === locale);
    const showPreviewNotification = supportedLocales.length !== locales.length - 1 && supportedLocales.length > 0;
    const filteredLocales = locales.filter((locale) => {
        if (checkIsValidLocale && !checkIsValidLocale(locale)) {
            return false;
        }
        return locale !== Locale.en_US;
    });
    const importTableAuxData = filteredLocales.map((locale) => {
        const supportedLocale = getLocale(locale);
        return {
            locale,
            status: supportedLocale?.status || LocalizationStatus.NOT_STARTED,
            last_updated: supportedLocale?.last_updated
                ? (supportedLocale?.last_updated).toDate().toISOString()
                : undefined,
            version: supportedLocale?.version,
            reviewPath: `${getReviewUrl()}/${locale}?assetType=${asset?.type}`,
            fileName: `${toSnakeCase(resource?.title)}_${locale}_v${supportedLocale?.version || '1'}.pdf`,
        };
    });
    const importTableAuxDataKey = JSON.stringify(importTableAuxData);
    const data = useMemo(() => importTableAuxData.map((allTheStuff) => {
        const { locale, fileName } = allTheStuff;
        return {
            ...allTheStuff,
            action: (_jsx(ImportPdfAction, { locale: locale, asset: asset, updateStudyConfig: handleUpdateStudyConfig, disabled: isStudyinDraft, fileName: fileName })),
        };
    }), [importTableAuxDataKey]);
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: toSentenceCase(resource?.title) || t('PDF Resources'), breadcrumbPages: breadcrumbPages }), _jsx("hr", { className: 'mb-6' }), _jsxs("div", { className: 'flex flex-col', children: [_jsx(SectionExport, { asset: asset, trackingContext: { context: 'study' }, status: getExportTableStatus(studyLocalizationStatus) }), _jsx("hr", { className: 'mt-6' }), showPreviewNotification && (_jsx(InlineNotification, { title: t('Preview unavailable'), subtitle: t('Preview links will be enabled after the first import') })), _jsx(TableImport, { data: data, hasAction: true, checklink: true })] })] }));
};
